// Public routes.ts
export const AUTH = `/auth`
export const SIGN_IN = `${AUTH}/signin`
export const FORGOT_PASSWORD = `${AUTH}/forgot-password`
export const AUTH_SELECT = `${AUTH}/select-company`
export const AUTH_SIGNUP = `${AUTH}/signup`

export const LANDING = `/landing`
export const LANDING_SEARCH = `lookup`
export const LANDING_SHARED_CONTACT = `/lookup/:id`

export const LANDING_SEARCH_PUBLIC = '/lookup'
export const LANDING_SEARCH_DETAIL = '/lookup/:id'
export const LANDING_NEW_DESIGN = '/new-landing'
// Private routes.ts
// Dashboard menu routes.ts
export const DASHBOARD = `/dashboard`
export const DASHBOARD_SALES = `${DASHBOARD}/dashboard-sales`
export const DASHBOARD_FINANCE = `${DASHBOARD}/dashboard-finance`
export const DASHBOARD_DASHBOARD = `${DASHBOARD}/dashboard`
export const DASHBOARD_DYNAMIC = `${DASHBOARD}/dashboard-dynamic`

// Directories menu routes.ts
export const CATALOG = `/catalog`

export const CATALOG_PRODUCT_LIST = `${CATALOG}/product/list`
export const CATALOG_PRODUCT_CREATE = `${CATALOG}/product/create`
export const CATALOG_PRODUCT_EDIT = `${CATALOG}/product/edit/:id/:tab`
export const CATALOG_PRODUCT_BULK_UPDATE = `${CATALOG}/product/bulk-update/:tab`

export const CATALOG_CATEGORY_LIST = `${CATALOG}/category/list`
export const CATALOG_CATEGORY_CREATE = `${CATALOG}/category/create`
export const CATALOG_CATEGORY_EDIT = `${CATALOG}/category/edit/:id`
export const CATALOG_ADD_PRODUCT = `${CATALOG}/category/add-assignee/:tab`

export const CATALOG_PRODUCT_CATEGORY = `${CATALOG}/product-category`

export const CATALOG_BRAND_LIST = `${CATALOG}/brand/list`
export const CATALOG_BRAND_CREATE = `${CATALOG}/brand/create`
export const CATALOG_BRAND_EDIT = `${CATALOG}/brand/edit/:id`

export const CATALOG_PACKAGE_LIST = `${CATALOG}/package/list`
export const CATALOG_PACKAGE_CREATE = `${CATALOG}/package/create`
export const CATALOG_PACKAGE_EDIT = `${CATALOG}/package/edit/:id`

export const CATALOG_DISCOUNT = `${CATALOG}/discount/list`
export const CATALOG_DISCOUNT_CREATE = `${CATALOG}/discount/create`
export const CATALOG_DISCOUNT_UPDATE = `${CATALOG}/discount/edit/:id`

export const CATALOG_MML = `${CATALOG}/mml/list`
export const CATALOG_MML_CREATE = `${CATALOG}/mml/create`
export const CATALOG_MML_EDIT = `${CATALOG}/mml/edit/:id`

export const CATALOG_COUNTRY_LIST = `${CATALOG}/country/list`
export const CATALOG_COUNTRY_CREATE = `${CATALOG}/country/create`
export const CATALOG_COUNTRY_UPDATE = `${CATALOG}/country/edit/:id`

export const SETTINGS_PRICE_LEVEL_LIST = `${CATALOG}/price-level/list`
export const SETTINGS_PRICE_LEVEL_CREATE = `${CATALOG}/price-level/create`
export const SETTINGS_PRICE_LEVEL_EDIT = `${CATALOG}/price-level/edit/:id/:tab`

export const SETTINGS_TERRITORY_LIST = `${CATALOG}/territory/list`
export const SETTINGS_TERRITORY_CREATE = `${CATALOG}/territory/create`
export const SETTINGS_TERRITORY_UPDATE = `${CATALOG}/territory/edit/:id`
export const CATALOG_TERRITORY_BULK = `${CATALOG}/territory/bulk-assignee/:id`
export const SETTINGS_CURRENCY_LIST = `${CATALOG}/currency/list`
export const SETTINGS_CURRENCY_CREATE = `${CATALOG}/currency/create`
export const SETTINGS_CURRENCY_UPDATE = `${CATALOG}/currency/edit/:id`

export const FINANCE_AGENT_LIST = `${CATALOG}/agent/list`
export const FINANCE_AGENT_CREATE = `${CATALOG}/agent/create`
export const FINANCE_AGENT_EDIT = `${CATALOG}/agent/edit/:tab/:id`

export const FINANCE_AGENT_CATEGORY_LIST = `${CATALOG}/agent-category/list`
export const FINANCE_AGENT_CATEGORY_CREATE = `${CATALOG}/agent-category/create`
export const FINANCE_AGENT_CATEGORY_EDIT = `${CATALOG}/agent-category/edit/:id`

export const CATALOG_AGENTS_ADDRESSES_MAP = `${CATALOG}/agent-addresses/map`

// Warehouse menu routes.ts
export const WAREHOUSE = `/warehouse`
export const WAREHOUSE_LIST = `${WAREHOUSE}/list`
export const WAREHOUSE_CREATE = `${WAREHOUSE}/create`
export const WAREHOUSE_UPDATE = `${WAREHOUSE}/update/:id`
export const WAREHOUSE_BULK = `${WAREHOUSE}/bulk-assignee`

export const WAREHOUSE_RECEIVE_LIST = `${WAREHOUSE}/receive/list`
export const WAREHOUSE_RECEIVE_CREATE = `${WAREHOUSE}/receive/create`
export const WAREHOUSE_RECEIVE_UPDATE = `${WAREHOUSE}/receive/update/:id`

export const WAREHOUSE_RETURN_SUPPLIER = `${WAREHOUSE}/return-supplier`

export const WAREHOUSE_MOVEMENT_LIST = `${WAREHOUSE}/movement/list`
export const WAREHOUSE_MOVEMENT_CREATE = `${WAREHOUSE}/movement/create`
export const WAREHOUSE_MOVEMENT_UPDATE = `${WAREHOUSE}/movement/update/:id`
export const WAREHOUSE_ADJUSTMENT_LIST = `${WAREHOUSE}/adjustment/list`
export const WAREHOUSE_ADJUSTMENT_CREATE = `${WAREHOUSE}/adjustment/create`
export const WAREHOUSE_ADJUSTMENT_UPDATE = `${WAREHOUSE}/adjustment/update/:id`

export const WAREHOUSE_GROUP_LIST = `${WAREHOUSE}/group/list`
export const WAREHOUSE_GROUP_CREATE = `${WAREHOUSE}/group/create`
export const WAREHOUSE_GROUP_UPDATE = `${WAREHOUSE}/group/update/:id`

export const FINANCE_PURCHASE_LIST = `${WAREHOUSE}/purchase/list`
export const FINANCE_PURCHASE_CREATE = `${WAREHOUSE}/purchase/create`
export const FINANCE_PURCHASE_UPDATE = `${WAREHOUSE}/purchase/update/:id`

// Visit menu routes.ts
export const CRM = '/crm'
export const VISIT = `/visit`
export const CLIENT_MANAGEMENT_LIST = `${VISIT}/visit`
export const CATALOG_VISIT_LIST = `${VISIT}/visit/list`
export const CATALOG_VISIT_CREATE = `${VISIT}/visit/create`
export const VISIT_VISIT_COMMENTS_LIST = `${VISIT}/visit/visit-comments/list`
export const VISIT_VISIT_COMMENTS_CREATE = `${VISIT}/visit/visit-comments/create`
export const VISIT_VISIT_COMMENTS_UPDATE = `${VISIT}/visit/visit-comments/edit/:id`

export const CATALOG_VISIT_DETAILS = `${VISIT}/visit/details/:id/:tab`
export const VISIT_VISIT_TYPE_LIST = `${VISIT}/visit-type/list`
export const VISIT_VISIT_TYPE_CREATE = `${VISIT}/visit-type/create`
export const VISIT_VISIT_TYPE_UPDATE = `${VISIT}/visit-type/edit/:id`
export const CRM_RETURN_TASK_LIST = `${VISIT}/task-return/list`
export const CRM_RETURN_TASK_CREATE = `${VISIT}/task-return/create`
export const CRM_RETURN_TASK_UPDATE = `${VISIT}/task-return/edit/:id`
export const VISIT_ATTACHMENT = `${VISIT}/attachment`

// Order menu routes.ts
export const SALE_ORDER = '/sale-order'
export const SALE_ORDER_LIST = `${SALE_ORDER}/list`
export const SALE_ORDER_CREATE = `${SALE_ORDER}/create`
export const SALE_ORDER_UPDATE = `${SALE_ORDER}/update/:id`

export const SALE_ORDER_PRE_ORDER = `${SALE_ORDER}/pre-order`
export const SALE_ORDER_PRE_ORDER_LIST = `${SALE_ORDER_PRE_ORDER}/list`
export const SALE_ORDER_PRE_ORDER_CREATE = `${SALE_ORDER_PRE_ORDER}/create`
export const SALE_ORDER_PRE_ORDER_UPDATE = `${SALE_ORDER_PRE_ORDER}/update/:id`

export const SALE_ORDER_RETURN = '/sale-order-return'
export const SALE_ORDER_RETURN_LIST = `${SALE_ORDER_RETURN}/list`
export const SALE_ORDER_RETURN_CREATE = `${SALE_ORDER_RETURN}/create`
export const SALE_ORDER_RETURN_UPDATE = `${SALE_ORDER_RETURN}/update/:id`

export const SALE_ORDER_SHIPMENT_LIST = `${SALE_ORDER}/shipment/list`
export const SALE_ORDER_SHIPMENT_CREATE = `${SALE_ORDER}/shipment/create`
export const SALE_ORDER_SHIPMENT_UPDATE = `${SALE_ORDER}/shipment/update/:id`

export const ORDER_PICKER_LIST = `${SALE_ORDER}/picker/list`
export const ORDER_PICKER_UPDATE = `${SALE_ORDER}/picker/:id`

// Finance menu routes.ts
export const FINANCE = `/finance`

export const FINANCE_CHART_OF_ACCOUNT_LISTING = `${FINANCE}/chart-of-account/list`
export const FINANCE_CHART_OF_ACCOUNT_CREATE = `${FINANCE}/chart-of-account/create`

export const FINANCE_SUPPLIER_PAYMENTS_LIST = `${FINANCE}/suppler-payments/list`
export const FINANCE_SUPPLIER_PAYMENTS_CREATE = `${FINANCE}/suppler-payments/create`
export const FINANCE_SUPPLIER_PAYMENTS_UPDATE = `${FINANCE}/suppler-payments/update/:id`

export const ORDER_PAYMENT_LIST = `${FINANCE}/payment/list`
export const ORDER_PAYMENT_CREATE = `${FINANCE}/payment/create`
export const ORDER_PAYMENT_UPDATE = `${FINANCE}/payment/update/:id`

export const FINANCE_MOVING_BETWEEN_CASH = `${FINANCE}/movement/transfer/list`
export const FINANCE_MOVING_BETWEEN_CASH_CREATE = `${FINANCE}/movement/transfer/create`
export const FINANCE_MOVING_BETWEEN_CASH_UPDATE = `${FINANCE}/movement/transfer/edit/:id`
export const FINANCE_MANUAL_ENTRY_LIST = `${FINANCE}/manual-entry/list`
export const FINANCE_MANUAL_ENTRY_CREATE = `${FINANCE}/manual-entry/create`
export const FINANCE_MANUAL_ENTRY_UPDATE = `${FINANCE}/manual-entry/update/:id`

export const ORDER_BULK_PAYMENT_LIST = `${FINANCE}/bulk-payment/list`
export const ORDER_BULK_PAYMENT_CREATE = `${FINANCE}/bulk-payment/create`
export const ORDER_BULK_PAYMENT_UPDATE = `${FINANCE}/bulk-payment/update/:id`

export const FINANCE_TRANSACTION_LIST = `${FINANCE}/transaction/list`

export const FINANCE_INVOICE_LIST = `${FINANCE}/invoice/list`

export const FINANCE_TAX_LIST = `${FINANCE}/taxes/list`

export const FINANCE_PURCHASE_RECEIVE_DETAIL = `${FINANCE}/purchase-receive/detail/:id`

export const FINANCE_CONSIGNMENT_LIST = `${FINANCE}/consignment/list`
export const FINANCE_CONSIGNMENT_CREATE = `${FINANCE}/consignment/create`
export const FINANCE_CONSIGNMENT_EDIT = `${FINANCE}/consignment/edit/:id`
// HR menu routes.ts
export const HR = `/hr`

export const HR_ROLES = `${HR}/roles`

export const HR_RIGHTS = `${HR}/rights`

export const HR_EMPLOYEE_LIST = `${HR}/employee/list`
export const HR_EMPLOYEE_CREATE = `${HR}/employee/create`
export const HR_EMPLOYEE_UPDATE = `${HR}/employee/update/:id`

export const HR_TRACKING = `${HR}/tracking`
export const HR_TRACKING_LIST = `${HR_TRACKING}/list`

export const HR_KPI = `${HR}/kpi`

export const HR_SALARY = `${HR}/salary`

// Marketing menu routes.ts
export const MARKETING = `/marketing`

export const MARKETING_BONUS_LIST = `${MARKETING}/bonus/list`
export const MARKETING_BONUS_CREATE = `${MARKETING}/bonus/create`
export const MARKETING_BONUS_UPDATE = `${MARKETING}/bonus/edit/:id`

export const MARKETING_ASSET_LIST = `${MARKETING}/asset/list`

export const MARKETING_RENT_LIST = `${MARKETING}/rent/list`
export const MARKETING_RENT_CREATE = `${MARKETING}/rent/create`
export const MARKETING_RENT_UPDATE = `${MARKETING}/rent/update/:id`

export const MARKETING_REVIEW_LIST = `${MARKETING}/review/list`

// Backend menu routes.ts
export const BACKEND = '/backend'
export const BACKEND_COMPANY_LIST = `${BACKEND}/company/list`
export const BACKEND_COMPANY_CREATE = `${BACKEND}/company/create`
export const BACKEND_COMPANY_ADD_USER = `${BACKEND}/company/user?`
export const BACKEND_COMPANY_EDIT = `${BACKEND}/company/edit/:tab/:id`

export const BACKEND_NEWS_LIST = `${BACKEND}/news/list`
export const BACKEND_NEWS_CREATE = `${BACKEND}/news/create`
export const BACKEND_NEWS_UPDATE = `${BACKEND}/news/edit/:id`

export const BACKEND_BILLING_LIST = `${BACKEND}/billing/list`
export const BACKEND_BILLING_CREATE = `${BACKEND}/billing/create`
export const BACKEND_BILLING_UPDATE = `${BACKEND}/billing/edit/:tab/:id`

export const BACKEND_BILLING_CATEGORY_LIST = `${BACKEND}/billing-category/list`
export const BACKEND_BILLING_CATEGORY_CREATE = `${BACKEND}/billing-category/create`
export const BACKEND_BILLING_CATEGORY_UPDATE = `${BACKEND}/billing-category/edit/:id`

export const BACKEND_SHARED_CONTACT_CATEGORY_LIST = `${BACKEND}/shared-contact-category/list`
export const BACKEND_SHARED_CONTACT_CATEGORY_CREATE = `${BACKEND}/shared-contact-category/create`
export const BACKEND_SHARED_CONTACT_CATEGORY_UPDATE = `${BACKEND}/shared-contact-category/edit/:id`

export const BACKEND_SHARED_CONTACT_LIST = `${BACKEND}/shared-contact/list`
export const BACKEND_SHARED_CONTACT_CREATE = `${BACKEND}/shared-contact/create`
export const BACKEND_SHARED_CONTACT_UPDATE = `${BACKEND}/shared-contact/edit/:id`

// Settings menu routes.ts
export const SETTINGS = `/settings`

export const SETTINGS_COMPANY_DETAILS = `${SETTINGS}/company-details`

export const SETTINGS_SYSTEM_SETTINGS = `${SETTINGS}/system-settings`

export const SETTINGS_MOBILE_SETTINGS = `${SETTINGS}/mobile-settings`

export const SETTINGS_CUSTOM_FIELD_LIST = `${SETTINGS}/custom-field/list`
export const SETTINGS_CUSTOM_FIELD_CREATE = `${SETTINGS}/custom-field/create`
export const SETTINGS_CUSTOM_FIELD_UPDATE = `${SETTINGS}/custom-field/update/:id`

export const SETTINGS_TIMESLOT_LIST = `${SETTINGS}/timeslot/list`
export const SETTINGS_TIMESLOT_CREATE = `${SETTINGS}/timeslot/create`
export const SETTINGS_TIMESLOT_UPDATE = `${SETTINGS}/timeslot/edit/:id`

export const SETTINGS_APPROVAL_LIST = `${SETTINGS}/approval/list`

export const SETTINGS_LICENSE = `${SETTINGS}/license`

export const SETTINGS_MEDIA_FILES = `${SETTINGS}/media-files`
export const SETTINGS_MEDIA_FILES_CREATE = `${SETTINGS}/media-files/create`
export const SETTINGS_MEDIA_FILES_DETAIL = `${SETTINGS}/media-files/detail/:id`
export const SETTINGS_MEDIA_FILES_DOCUMENT_CREATE = `${SETTINGS}/media-files/document/create`
export const SETTINGS_MEDIA_FILES_DOCUMENT_UPDATE = `${SETTINGS}/media-files/document/edit/:id`
export const SETTINGS_MEDIA_FILES_UPDATE = `${SETTINGS}/media-files/edit/:id`
export const SETTINGS_LISTING_UPDATE = `${SETTINGS}/listing/columns`
// Reports menu routes.ts
export const REPORTS = '/reports'

export const REPORTS_ALL = `${REPORTS}/all`

export const REPORTS_WAREHOUSE_REMAINS = `${REPORTS}/warehouse-stock`

export const REPORTS_CONTACT_REPORT = `${REPORTS}/contact-reconciliation`

export const REPORTS_CASH = `${REPORTS}/cash-reconciliation `

export const REPORTS_PRODUCT = `${REPORTS}/product-reconciliation`

export const REPORTS_TEMPLATES = `${REPORTS}/templates`

export const REPORTS_CONSTRUCTOR = `${REPORTS}/constructor`

export const REPORTS_CONSTRUCTOR_PRINT_FORMS = `${REPORTS}/constructor-print-forms`

export const REPORTS_AUDIT = `${REPORTS}/audit`
