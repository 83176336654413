import * as ROUTES from 'constants/Routes'

import React, { lazy, Suspense } from 'react'
import useAuth, { AUTHORIZED_STATUS } from 'hooks/useAuth'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Loading from 'components/loading'
// Public page
const LandingSearchContainer =
  lazy(() => import('modules/landing/containers/LandingSearchContainer'))
const NewDesignContainer =
  lazy(() => import('modules/new-design/containers/NewDesignContainer'))
const SignInContainer =
  lazy(() => import('modules/auth/containers/SignInContainer'))
const SelectCompanyContainer =
  lazy(() => import('modules/auth/containers/SelectCompanyContainer'))
const PublicCompanyCreateContainer =
  lazy(() => import('modules/public/company/containers/PublicCompanyCreateContainer'))
// Private contact pages details
const PrivateDomainSharedContact =
  lazy(() => import('modules/landing/private/containers/PrivateDomainSharedContact'))
const SharedPrivateDetailContainer =
  lazy(() => import('modules/landing/shared-private-detail/containers/SharedPrivateDetailContainer'))
// Contragent routes
const TerritoryListContainer =
  lazy(() => import('modules/catalogs/agents/territory/containers/TerritoryListContainer'))
const TerritoryCreateContainer =
  lazy(() => import('modules/catalogs/agents/territory/containers/TerritoryCreateContainer'))
const TerritoryUpdateContainer =
  lazy(() => import('modules/catalogs/agents/territory/containers/TerritoryUpdateContainer'))
const TerritoryBulkContainer =
  lazy(() => import('modules/catalogs/agents/territory/containers/TerritoryBulkContainer'))
const AgentListContainer =
  lazy(() => import('modules/catalogs/agents/agent/containers/AgentListContainer'))
const AgentCreateContainer =
  lazy(() => import('modules/catalogs/agents/agent/containers/AgentCreateContainer'))
const AgentUpdateContainer =
  lazy(() => import('modules/catalogs/agents/agent/containers/AgentUpdateContainer'))
const AgentsAddressesListContainer =
  lazy(() => import('modules/catalogs/agents/agents-addresses/containers/AgentsAddressesListContainer'))
const ContactCategoryListContainer =
  lazy(() => import('modules/catalogs/agents/agent-category/containers/AgentCategoryListContainer'))
const AgentCategoryCreateContainer =
  lazy(() => import('modules/catalogs/agents/agent-category/containers/AgentCategoryCreateContainer'))
const AgentCategoryUpdateContainer =
  lazy(() => import('modules/catalogs/agents/agent-category/containers/AgentCategoryUpdateContainer'))
// Visit routes
const TaskReturnListContainer =
  lazy(() => import('modules/visit/task-return/containers/TaskReturnListContainer'))
const TaskReturnCreateContainer =
  lazy(() => import('modules/visit/task-return/containers/TaskReturnCreateContainer'))
const TaskReturnUpdateContainer =
  lazy(() => import('modules/visit/task-return/containers/TaskReturnUpdateContainer'))
const VisitListContainer =
  lazy(() => import('modules/visit/visit/containers/VisitListContainer'))
const VisitCreateContainer =
  lazy(() => import('modules/visit/visit/containers/VisitCreateContainer'))
const VisitUpdateContainer =
  lazy(() => import('modules/visit/visit/containers/VisitUpdateContainer'))
const VisitTypeListContainer =
  lazy(() => import('modules/visit/visit-type/containers/VisitTypeListContainer'))
const VisitTypeCreateContainer =
  lazy(() => import('modules/visit/visit-type/containers/VisitTypeCreateContainer'))
const VisitTypeUpdateContainer =
  lazy(() => import('modules/visit/visit-type/containers/VisitTypeUpdateContainer'))
const VisitCommentListContainer =
  lazy(() => import('modules/visit/visit-comments/containers/VisitCommentListContainer'))
const VisitCommentCreateContainer =
  lazy(() => import('modules/visit/visit-comments/containers/VisitCommentCreateContainer'))
const VisitCommentUpdateContainer =
  lazy(() => import('modules/visit/visit-comments/containers/VisitCommentUpdateContainer'))
// Products routes
const ProductListContainer =
  lazy(() => import('modules/catalogs/products/product/containers/ProductListContainer'))
const ProductCreateContainer =
  lazy(() => import('modules/catalogs/products/product/containers/ProductCreateContainer'))
const ProductUpdateContainer =
  lazy(() => import('modules/catalogs/products/product/containers/ProductUpdateContainer'))
const ProductBulkUpdateContainer =
  lazy(() => import('modules/catalogs/products/product/containers/ProductBulkUpdateContainer'))
const DiscountListContainer =
  lazy(() => import('modules/catalogs/products/discount/containers/DiscountListContainer'))
const DiscountCreateContainer =
  lazy(() => import('modules/catalogs/products/discount/containers/DiscountCreateContainer'))
const DiscountUpdateContainer =
  lazy(() => import('modules/catalogs/products/discount/containers/DiscountUpdateContainer'))
const CategoryListContainer =
  lazy(() => import('modules/catalogs/products/category/containers/CategoryListContainer'))
const CategoryCreateContainer =
  lazy(() => import('modules/catalogs/products/category/containers/CategoryCreateContainer'))
const CategoryUpdateContainer =
  lazy(() => import('modules/catalogs/products/category/containers/CategoryUpdateContainer'))
const CategoryAddProductContainer =
  lazy(() => import('modules/catalogs/products/category/containers/CategoryAddProductContainer'))
const BrandListContainer =
  lazy(() => import('modules/catalogs/products/brand/containers/BrandListContainer'))
const BrandCreateContainer =
  lazy(() => import('modules/catalogs/products/brand/containers/BrandCreateContainer'))
const BrandUpdateContainer =
  lazy(() => import('modules/catalogs/products/brand/containers/BrandUpdateContainer'))
const PackageListContainer =
  lazy(() => import('modules/catalogs/products/product-package/containers/PackageListContainer'))
const PackageCreateContainer =
  lazy(() => import('modules/catalogs/products/product-package/containers/PackageCreateContainer'))
const PackageUpdateContainer =
  lazy(() => import('modules/catalogs/products/product-package/containers/PackageUpdateContainer'))
const CountryListContainer =
  lazy(() => import('modules/catalogs/products/country/containers/CountryListContainer'))
const CountryUpdateContainer =
  lazy(() => import('modules/catalogs/products/country/containers/CountryUpdateContainer'))
const CountryCreateContainer =
  lazy(() => import('modules/catalogs/products/country/containers/CountryCreateContainer'))
const PriceLevelListContainer =
  lazy(() => import('modules/catalogs/products/price-level/containers/PriceLevelListContainer'))
const PriceLevelCreateContainer =
  lazy(() => import('modules/catalogs/products/price-level/containers/PriceLevelCreateContainer'))
const PriceLevelUpdateContainer =
  lazy(() => import('modules/catalogs/products/price-level/containers/PriceLevelUpdateContainer'))
const CurrencyListContainer =
  lazy(() => import('modules/catalogs/products/currency/containers/CurrencyListContainer'))
const CurrencyCreateContainer =
  lazy(() => import('modules/catalogs/products/currency/containers/CurrencyCreateContainer'))
const CurrencyUpdateContainer =
  lazy(() => import('modules/catalogs/products/currency/containers/CurrencyUpdateContainer'))
const UnitMeasurementListContainer =
  lazy(() => import('modules/catalogs/products/mml/containers/UnitMeasurementListContainer'))
const UnitMeasurementCreateContainer =
  lazy(() => import('modules/catalogs/products/mml/containers/UnitMeasurementCreateContainer'))
const UnitMeasurementUpdateContainer =
  lazy(() => import('modules/catalogs/products/mml/containers/UnitMeasurementUpdateContainer'))
// Settings routes
const CustomFieldListContainer =
  lazy(() => import('modules/settings/custom-field/containers/CustomFieldListContainer'))
const CustomFieldCreateContainer =
  lazy(() => import('modules/settings/custom-field/containers/CustomFieldCreateContainer'))
const CustomFieldUpdateContainer =
  lazy(() => import('modules/settings/custom-field/containers/CustomFieldUpdateContainer'))
const ApprovalProcessCreateContainer =
  lazy(() => import('modules/settings/approval-process/containers/ApprovalProcessCreateContainer'))
const FolderListContainer =
  lazy(() => import('modules/settings/document/containers/FolderListContainer'))
const FolderCreateContainer =
  lazy(() => import('modules/settings/document/containers/FolderCreateContainer'))
const DocumentListContainer =
  lazy(() => import('modules/settings/document/containers/DocumentListContainers'))
const ListingUpdateContainer =
  lazy(() => import('modules/settings/listing/containers/ListingUpdateContainer'))
const DocumentCreateContainer =
  lazy(() => import('modules/settings/document/containers/DocumentCreateContainer'))
const DocumentUpdateContainer =
  lazy(() => import('modules/settings/document/containers/DocumentUpdateContainer'))
const TimeSlotListContainer =
  lazy(() => import('modules/settings/user-time-slot/containers/TimeSlotListContainer'))
const TimeSlotCreateContainer =
  lazy(() => import('modules/settings/user-time-slot/containers/TimeSlotCreateContainer'))
const TimeSlotUpdateContainer =
  lazy(() => import('modules/settings/user-time-slot/containers/TimeSlotUpdateContainer'))
// Sales order routes
const SaleOrderListContainer =
  lazy(() => import('modules/sale-order/sale-order/containers/SaleOrderListContainer'))
const SaleOrderCreateContainer =
  lazy(() => import('modules/sale-order/sale-order/containers/SaleOrderCreateContainer'))
const SaleOrderUpdateContainer =
  lazy(() => import('modules/sale-order/sale-order/containers/SaleOrderUpdateContainer'))
const PreOrderListContainer =
  lazy(() => import('modules/sale-order/pre-order/containers/PreOrderListContainer'))
const PreOrderCreateContainer =
  lazy(() => import('modules/sale-order/pre-order/containers/PreOrderCreateContainer'))
const PreOrderUpdateContainer =
  lazy(() => import('modules/sale-order/pre-order/containers/PreOrderUpdateContainer'))
const ShipmentListContainer =
  lazy(() => import('modules/sale-order/shipment/containers/ShipmentListContainer'))
const ShipmentUpdateContainer =
  lazy(() => import('modules/sale-order/shipment/containers/ShipmentUpdateContainer'))
const SaleOrderReturnListContainer =
  lazy(() => import('modules/sale-order/sale-order-return/containers/SaleOrderReturnListContainer'))
const SaleOrderReturnCreateContainer =
  lazy(() => import('modules/sale-order/sale-order-return/containers/SaleOrderReturnCreateContainer'))
const SaleOrderReturnUpdateContainer =
  lazy(() => import('modules/sale-order/sale-order-return/containers/SaleOrderReturnUpdateContainer'))
const PickerListContainer =
  lazy(() => import('modules/sale-order/pick/containers/PickListContainer'))
const PickUpdateContainer =
  lazy(() => import('modules/sale-order/pick/containers/PickUpdateContainer'))
// Finance routes
const BulkPaymentListContainer =
  lazy(() => import('modules/finance/bulk-payment/containers/BulkPaymentListContainer'))
const BulkPaymentCreateContainer =
  lazy(() => import('modules/finance/bulk-payment/containers/BulkPaymentCreateContainer'))
const BulkPaymentUpdateContainer =
  lazy(() => import('modules/finance/bulk-payment/containers/BulkPaymentUpdateContainer'))
const ConsignmentListContainer =
  lazy(() => import('modules/finance/consignment/containers/ConsignmentListContainer'))
const ConsignmentCreateContainer =
  lazy(() => import('modules/finance/consignment/containers/ConsignmentCreateContainer'))
const ConsignmentUpdateContainer =
  lazy(() => import('modules/finance/consignment/containers/ConsignmentUpdateContainer'))
const TransferCashListContainer =
  lazy(() => import('modules/finance/transfer-cash/containers/TransferCashListContainer'))
const TransferCashCreateContainer =
  lazy(() => import('modules/finance/transfer-cash/containers/TransferCashCreateContainer'))
const TransferCashUpdateContainer =
  lazy(() => import('modules/finance/transfer-cash/containers/TransferCashUpdateContainer'))
const PurchaseOrderListContainer =
  lazy(() => import('modules/finance/purchase-order/containers/PurchaseListContainer'))
const PurchaseCreateContainer =
  lazy(() => import('modules/finance/purchase-order/containers/PurchaseCreateContainer'))
const PurchaseUpdateContainer =
  lazy(() => import('modules/finance/purchase-order/containers/PurchaseUpdateContainer'))
const ReceivePODetailContainer =
  lazy(() => import('modules/finance/purchase-order/containers/ReceivePODetailContainer'))
const PaymentListContainer =
  lazy(() => import('modules/finance/payment/containers/PaymentListContainer'))
const PaymentCreateContainer =
  lazy(() => import('modules/finance/payment/containers/PaymentCreateContainer'))
const PaymentUpdateContainer =
  lazy(() => import('modules/finance/payment/containers/PaymentUpdateContainer'))
const ManualEntryListContainer =
  lazy(() => import('modules/finance/manual-entry/containers/ManualEntryListContainer'))
const ManualEntryCreateContainer =
  lazy(() => import('modules/finance/manual-entry/containers/ManualEntryCreateContainer'))
const ManualEntryUpdateContainer =
  lazy(() => import('modules/finance/manual-entry/containers/ManualEntryUpdateContainer'))
const TransactionListContainer =
  lazy(() => import('modules/finance/transaction/containers/TransactionListContainer'))
const SupplierPaymentsListContainer =
  lazy(() => import('modules/finance/supplier-payments/containers/SupplierPaymentsListContainer'))
const SupplierPaymentsCreateContainer =
  lazy(() => import('modules/finance/supplier-payments/containers/SupplierPaymentsCreateContainer'))
const SupplierPaymentsUpdateContainer =
  lazy(() => import('modules/finance/supplier-payments/containers/SupplierPaymentsUpdateContainer'))
const ChartOfAccountingListContainer =
  lazy(() => import('modules/finance/chart-of-account/containers/ChartOfAccountingListContainer'))
const ChartOfAccountCreateContainer =
  lazy(() => import('modules/finance/chart-of-account/containers/ChartOfAccountCreateContainer'))
// Warehouse routes
const AdjustmentListContainer =
  lazy(() => import('modules/warehouse/adjustment/containers/AdjustmentListContainer'))
const AdjustmentCreateContainer =
  lazy(() => import('modules/warehouse/adjustment/containers/AdjustmentCreateContainer'))
const AdjustmentUpdateContainer =
  lazy(() => import('modules/warehouse/adjustment/containers/AdjustmentUpdateContainer'))
const WarehouseGroupListContainer =
  lazy(() => import('modules/warehouse/group/containers/WarehouseGroupListContainer'))
const WarehouseGroupCreateContainer =
  lazy(() => import('modules/warehouse/group/containers/WarehouseGroupCreateContainer'))
const WarehouseGroupUpdateContainer =
  lazy(() => import('modules/warehouse/group/containers/WarehouseGroupUpdateContainer'))
const WarehouseListContainer =
  lazy(() => import('modules/warehouse/warehouse/containers/WarehouseListContainer'))
const WarehouseCreateContainer =
  lazy(() => import('modules/warehouse/warehouse/containers/WarehouseCreateContainer'))
const WarehouseUpdateContainer =
  lazy(() => import('modules/warehouse/warehouse/containers/WarehouseUpdateContainer'))
const WarehouseBulkContainer =
  lazy(() => import('modules/warehouse/warehouse/containers/WarehouseBulkContainer'))
const ReceiveListContainer =
  lazy(() => import('modules/warehouse/receive/containers/ReceiveListContainer'))
const ReceiveCreateContainer =
  lazy(() => import('modules/warehouse/receive/containers/ReceiveCreateContainer'))
const ReceiveUpdateContainer =
  lazy(() => import('modules/warehouse/receive/containers/ReceiveUpdateContainer'))
const TransferListContainer =
  lazy(() => import('modules/warehouse/transfer/containers/TransferListContainer'))
const TransferCreateContainer =
  lazy(() => import('modules/warehouse/transfer/containers/TransferCreateContainer'))
const TransferUpdateContainer =
  lazy(() => import('modules/warehouse/transfer/containers/TransferUpdateContainer'))
// HR routes
const EmployeeListContainer =
  lazy(() => import('modules/hr/employee/containers/EmployeeListContainer'))
const EmployeeCreateContainer =
  lazy(() => import('modules/hr/employee/containers/EmployeeCreateContainer'))
const EmployeeUpdateContainer =
  lazy(() => import('modules/hr/employee/containers/EmployeeUpdateContainer'))
const TrackingListContainer =
  lazy(() => import('modules/hr/tracking/containers/TrackingListContainer'))
// Backend routes
const CompanyListContainer =
  lazy(() => import('modules/backend/company/containers/CompanyListContainer'))
const CompanyCreateContainer =
  lazy(() => import('modules/backend/company/containers/CompanyCreateContainer'))
const CompanyCreateUserContainer =
  lazy(() => import('modules/backend/company/containers/CompanyCreateUserContainer'))
const CompanyUpdateContainer =
  lazy(() => import('modules/backend/company/containers/CompanyUpdateContainer'))
const NewsListContainer =
  lazy(() => import('modules/backend/news/containers/NewsListContainer'))
const NewsCreateContainer =
  lazy(() => import('modules/backend/news/containers/NewsCreateContainer'))
const NewsUpdateContainer =
  lazy(() => import('modules/backend/news/containers/NewsUpdateContainer'))
const BillingPlanListContainer =
  lazy(() => import('modules/backend/billing/containers/BillingPlanListContainer'))
const BillingPlanCreateContainer =
  lazy(() => import('modules/backend/billing/containers/BillingPlanCreateContainer'))
const BillingPlanUpdateContainer =
  lazy(() => import('modules/backend/billing/containers/BillingPlanUpdateContainer'))
const BillingPLanCategoryListContainer =
  lazy(() => import('modules/backend/billing-plan-category/containers/BillingPLanCategoryListContainer'))
const BillingPlanCategoryCreateContainer =
  lazy(() => import('modules/backend/billing-plan-category/containers/BillingPlanCategoryCreateContainer'))
const BillingPlanCategoryUpdateContainer =
  lazy(() => import('modules/backend/billing-plan-category/containers/BillingPlanCategoryUpdateContainer'))
const SharedContactCategoryListContainer =
  lazy(() => import('modules/backend/shared-category/containers/SharedContactCategoryListContainer'))
const SharedContactCategoryCreateContainer =
  lazy(() => import('modules/backend/shared-category/containers/SharedContactCategoryCreateContainer'))
const SharedContactCategoryUpdateContainer =
  lazy(() => import('modules/backend/shared-category/containers/SharedContactCategoryUpdateContainer'))
const SharedContactListContainer =
  lazy(() => import('modules/backend/shared-contact/containers/SharedContactListContainer'))
const SharedContactCreateContainer =
  lazy(() => import('modules/backend/shared-contact/containers/SharedContactCreateContainer'))
const SharedContactUpdateContainer =
  lazy(() => import('modules/backend/shared-contact/containers/SharedContactUpdateContainer'))
// Marketing routes
const RentListContainer =
  lazy(() => import('modules/marketing/rent/containers/RentListContainer'))
const RentCreateContainer =
  lazy(() => import('modules/marketing/rent/containers/RentCreateContainer'))
const RentUpdateContainer =
  lazy(() => import('modules/marketing/rent/containers/RentUpdateContainer'))
const BonusListContainer =
  lazy(() => import('modules/marketing/bonus/containers/BonusListingContainer'))
const BonusCreateContainer =
  lazy(() => import('modules/marketing/bonus/containers/BonusCreateContainer'))
const BonusUpdateContainer =
  lazy(() => import('modules/marketing/bonus/containers/BonusUpdateContainer'))
// Others
const StockListContainer =
  lazy(() => import('modules/reports/stock/containers/StockListContainer'))

function AppRoutes () {
  const { isApp, isAuthorized, setIsAuthorized } = useAuth()

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          {isApp && isAuthorized === AUTHORIZED_STATUS.YES && (
            <>
              <Route path={ROUTES.LANDING_SEARCH} element={<LandingSearchContainer />} />
              <Route
                path={ROUTES.LANDING_SHARED_CONTACT}
                element={<NewDesignContainer />}
              />
              <Route path="*" element={<Navigate replace to={ROUTES.LANDING_SEARCH} />} />
            </>
          )}
          {isApp && isAuthorized === AUTHORIZED_STATUS.NO && (
            <>
              <Route path={ROUTES.LANDING_SEARCH} element={<LandingSearchContainer />} />
              <Route
                path={ROUTES.LANDING_SHARED_CONTACT}
                element={<NewDesignContainer />}
              />
              <Route path="*" element={<Navigate replace to={ROUTES.LANDING_SEARCH} />} />
            </>
          )}
          {(!isApp && isAuthorized === AUTHORIZED_STATUS.NO) && (
            <>
              <Route
                path={ROUTES.SIGN_IN}
                element={<SignInContainer setIsAuthorized={setIsAuthorized} />}
              />
              <Route
                path={ROUTES.AUTH_SELECT}
                element={<SelectCompanyContainer setIsAuthorized={setIsAuthorized} />}
              />
              <Route path={ROUTES.AUTH_SIGNUP} element={<PublicCompanyCreateContainer />} />
              <Route path={ROUTES.LANDING_SEARCH_PUBLIC} element={<PrivateDomainSharedContact />} />
              <Route path={ROUTES.LANDING_SEARCH_DETAIL} element={<SharedPrivateDetailContainer />} />
              <Route path="*" element={<Navigate replace to={ROUTES.LANDING_SEARCH} />} />
            </>
          )}
          {(!isApp && isAuthorized === AUTHORIZED_STATUS.YES) && (
            <>
              <Route path={ROUTES.LANDING_NEW_DESIGN} element={<NewDesignContainer />} />
              <Route path={ROUTES.LANDING_SEARCH_PUBLIC} element={<PrivateDomainSharedContact />} />
              <Route path={ROUTES.LANDING_SEARCH_DETAIL} element={<SharedPrivateDetailContainer />} />
              <Route path={ROUTES.SETTINGS_TERRITORY_LIST} element={<TerritoryListContainer />} />
              <Route path={ROUTES.SETTINGS_TERRITORY_CREATE} element={<TerritoryCreateContainer />} />
              <Route path={ROUTES.SETTINGS_TERRITORY_UPDATE} element={<TerritoryUpdateContainer />} />
              <Route path={ROUTES.CATALOG_TERRITORY_BULK} element={<TerritoryBulkContainer />} />
              <Route path={ROUTES.CRM_RETURN_TASK_LIST} element={<TaskReturnListContainer />} />
              <Route path={ROUTES.CRM_RETURN_TASK_CREATE} element={<TaskReturnCreateContainer />} />
              <Route path={ROUTES.CRM_RETURN_TASK_UPDATE} element={<TaskReturnUpdateContainer />} />

              <Route path={ROUTES.CATALOG_PRODUCT_LIST} element={<ProductListContainer />} />
              <Route path={ROUTES.CATALOG_PRODUCT_CREATE} element={<ProductCreateContainer />} />
              <Route path={ROUTES.CATALOG_PRODUCT_EDIT} element={<ProductUpdateContainer />} />
              <Route path={ROUTES.CATALOG_PRODUCT_BULK_UPDATE} element={<ProductBulkUpdateContainer />} />

              <Route path={ROUTES.CATALOG_DISCOUNT} element={<DiscountListContainer />} />
              <Route path={ROUTES.CATALOG_DISCOUNT_CREATE} element={<DiscountCreateContainer />} />
              <Route path={ROUTES.CATALOG_DISCOUNT_UPDATE} element={<DiscountUpdateContainer />} />

              <Route path={ROUTES.SETTINGS_CUSTOM_FIELD_LIST} element={<CustomFieldListContainer />} />
              <Route
                path={ROUTES.SETTINGS_CUSTOM_FIELD_CREATE}
                element={<CustomFieldCreateContainer />}
              />
              <Route
                path={ROUTES.SETTINGS_CUSTOM_FIELD_UPDATE}
                element={<CustomFieldUpdateContainer />}
              />
              <Route path={ROUTES.SETTINGS_APPROVAL_LIST} element={<ApprovalProcessCreateContainer />} />

              <Route path={ROUTES.CATALOG_CATEGORY_LIST} element={<CategoryListContainer />} />
              <Route path={ROUTES.CATALOG_CATEGORY_CREATE} element={<CategoryCreateContainer />} />
              <Route path={ROUTES.CATALOG_CATEGORY_EDIT} element={<CategoryUpdateContainer />} />
              <Route path={ROUTES.CATALOG_ADD_PRODUCT} element={<CategoryAddProductContainer />} />

              <Route path={ROUTES.CATALOG_BRAND_LIST} element={<BrandListContainer />} />
              <Route path={ROUTES.CATALOG_BRAND_CREATE} element={<BrandCreateContainer />} />
              <Route path={ROUTES.CATALOG_BRAND_EDIT} element={<BrandUpdateContainer />} />

              <Route path={ROUTES.CATALOG_PACKAGE_LIST} element={<PackageListContainer />} />
              <Route path={ROUTES.CATALOG_PACKAGE_CREATE} element={<PackageCreateContainer />} />
              <Route path={ROUTES.CATALOG_PACKAGE_EDIT} element={<PackageUpdateContainer />} />

              <Route path={ROUTES.CATALOG_COUNTRY_LIST} element={<CountryListContainer />} />
              <Route path={ROUTES.CATALOG_COUNTRY_UPDATE} element={<CountryUpdateContainer />} />
              <Route path={ROUTES.CATALOG_COUNTRY_CREATE} element={<CountryCreateContainer />} />

              <Route path={ROUTES.SETTINGS_PRICE_LEVEL_LIST} element={<PriceLevelListContainer />} />
              <Route path={ROUTES.SETTINGS_PRICE_LEVEL_CREATE} element={<PriceLevelCreateContainer />} />
              <Route path={ROUTES.SETTINGS_PRICE_LEVEL_EDIT} element={<PriceLevelUpdateContainer />} />
              <Route path={ROUTES.SETTINGS_CURRENCY_LIST} element={<CurrencyListContainer />} />
              <Route path={ROUTES.SETTINGS_CURRENCY_CREATE} element={<CurrencyCreateContainer />} />
              <Route path={ROUTES.SETTINGS_CURRENCY_UPDATE} element={<CurrencyUpdateContainer />} />
              <Route path={ROUTES.SETTINGS_MEDIA_FILES} element={<FolderListContainer />} />
              <Route path={ROUTES.SETTINGS_MEDIA_FILES_CREATE} element={<FolderCreateContainer />} />
              <Route path={ROUTES.SETTINGS_MEDIA_FILES_DETAIL} element={<DocumentListContainer />} />
              <Route path={ROUTES.SETTINGS_LISTING_UPDATE} element={<ListingUpdateContainer />} />
              <Route
                path={ROUTES.SETTINGS_MEDIA_FILES_DOCUMENT_CREATE}
                element={<DocumentCreateContainer />}
              />
              <Route
                path={ROUTES.SETTINGS_MEDIA_FILES_DOCUMENT_UPDATE}
                element={<DocumentUpdateContainer />}
              />
              <Route path={ROUTES.SETTINGS_TIMESLOT_LIST} element={<TimeSlotListContainer />} />
              <Route path={ROUTES.SETTINGS_TIMESLOT_CREATE} element={<TimeSlotCreateContainer />} />
              <Route path={ROUTES.SETTINGS_TIMESLOT_UPDATE} element={<TimeSlotUpdateContainer />} />

              <Route path={ROUTES.CATALOG_VISIT_LIST} element={<VisitListContainer />} />
              <Route path={ROUTES.CATALOG_VISIT_CREATE} element={<VisitCreateContainer />} />
              <Route path={ROUTES.CATALOG_VISIT_DETAILS} element={<VisitUpdateContainer />} />
              <Route path={ROUTES.VISIT_VISIT_TYPE_LIST} element={<VisitTypeListContainer />} />
              <Route path={ROUTES.VISIT_VISIT_TYPE_CREATE} element={<VisitTypeCreateContainer />} />
              <Route path={ROUTES.VISIT_VISIT_TYPE_UPDATE} element={<VisitTypeUpdateContainer />} />
              <Route path={ROUTES.VISIT_VISIT_COMMENTS_LIST} element={<VisitCommentListContainer />} />
              <Route
                path={ROUTES.VISIT_VISIT_COMMENTS_CREATE}
                element={<VisitCommentCreateContainer />}
              />
              <Route
                path={ROUTES.VISIT_VISIT_COMMENTS_UPDATE}
                element={<VisitCommentUpdateContainer />}
              />

              <Route path={ROUTES.CATALOG_MML} element={<UnitMeasurementListContainer />} />
              <Route path={ROUTES.CATALOG_MML_CREATE} element={<UnitMeasurementCreateContainer />} />
              <Route path={ROUTES.CATALOG_MML_EDIT} element={<UnitMeasurementUpdateContainer />} />

              <Route path={ROUTES.SALE_ORDER_LIST} element={<SaleOrderListContainer />} />
              <Route path={ROUTES.SALE_ORDER_CREATE} element={<SaleOrderCreateContainer />} />
              <Route path={ROUTES.SALE_ORDER_UPDATE} element={<SaleOrderUpdateContainer />} />
              <Route path={ROUTES.SALE_ORDER_PRE_ORDER_LIST} element={<PreOrderListContainer />} />
              <Route path={ROUTES.SALE_ORDER_PRE_ORDER_CREATE} element={<PreOrderCreateContainer />} />
              <Route path={ROUTES.SALE_ORDER_PRE_ORDER_UPDATE} element={<PreOrderUpdateContainer />} />
              <Route path={ROUTES.SALE_ORDER_SHIPMENT_LIST} element={<ShipmentListContainer />} />
              <Route path={ROUTES.SALE_ORDER_SHIPMENT_UPDATE} element={<ShipmentUpdateContainer />} />
              <Route path={ROUTES.SALE_ORDER_RETURN_LIST} element={<SaleOrderReturnListContainer />} />
              <Route
                path={ROUTES.SALE_ORDER_RETURN_CREATE}
                element={<SaleOrderReturnCreateContainer />}
              />
              <Route
                path={ROUTES.SALE_ORDER_RETURN_UPDATE}
                element={<SaleOrderReturnUpdateContainer />}
              />
              <Route path={ROUTES.ORDER_PICKER_LIST} element={<PickerListContainer />} />
              <Route path={ROUTES.ORDER_PICKER_UPDATE} element={<PickUpdateContainer />} />
              <Route path={ROUTES.ORDER_BULK_PAYMENT_LIST} element={<BulkPaymentListContainer />} />
              <Route path={ROUTES.ORDER_BULK_PAYMENT_CREATE} element={<BulkPaymentCreateContainer />} />
              <Route path={ROUTES.ORDER_BULK_PAYMENT_UPDATE} element={<BulkPaymentUpdateContainer />} />

              <Route path={ROUTES.WAREHOUSE_LIST} element={<WarehouseListContainer />} />
              <Route path={ROUTES.WAREHOUSE_CREATE} element={<WarehouseCreateContainer />} />
              <Route path={ROUTES.WAREHOUSE_UPDATE} element={<WarehouseUpdateContainer />} />
              <Route path={ROUTES.WAREHOUSE_BULK} element={<WarehouseBulkContainer />} />

              <Route path={ROUTES.WAREHOUSE_RECEIVE_LIST} element={<ReceiveListContainer />} />
              <Route path={ROUTES.WAREHOUSE_RECEIVE_CREATE} element={<ReceiveCreateContainer />} />
              <Route path={ROUTES.WAREHOUSE_RECEIVE_UPDATE} element={<ReceiveUpdateContainer />} />

              <Route path={ROUTES.FINANCE_PURCHASE_LIST} element={<PurchaseOrderListContainer />} />
              <Route path={ROUTES.FINANCE_PURCHASE_CREATE} element={<PurchaseCreateContainer />} />
              <Route path={ROUTES.FINANCE_PURCHASE_UPDATE} element={<PurchaseUpdateContainer />} />
              <Route
                path={ROUTES.FINANCE_PURCHASE_RECEIVE_DETAIL}
                element={<ReceivePODetailContainer />}
              />
              <Route path={ROUTES.FINANCE_CONSIGNMENT_LIST} element={<ConsignmentListContainer />} />
              <Route path={ROUTES.FINANCE_CONSIGNMENT_CREATE} element={<ConsignmentCreateContainer />} />
              <Route path={ROUTES.FINANCE_CONSIGNMENT_EDIT} element={<ConsignmentUpdateContainer />} />
              <Route path={ROUTES.FINANCE_MOVING_BETWEEN_CASH} element={<TransferCashListContainer />} />
              <Route
                path={ROUTES.FINANCE_MOVING_BETWEEN_CASH_CREATE}
                element={<TransferCashCreateContainer />}
              />
              <Route
                path={ROUTES.FINANCE_MOVING_BETWEEN_CASH_UPDATE}
                element={<TransferCashUpdateContainer />}
              />

              <Route path={ROUTES.ORDER_PAYMENT_LIST} element={<PaymentListContainer />} />
              <Route path={ROUTES.ORDER_PAYMENT_CREATE} element={<PaymentCreateContainer />} />
              <Route path={ROUTES.ORDER_PAYMENT_UPDATE} element={<PaymentUpdateContainer />} />

              <Route path={ROUTES.FINANCE_MANUAL_ENTRY_LIST} element={<ManualEntryListContainer />} />
              <Route path={ROUTES.FINANCE_MANUAL_ENTRY_CREATE} element={<ManualEntryCreateContainer />} />
              <Route path={ROUTES.FINANCE_MANUAL_ENTRY_UPDATE} element={<ManualEntryUpdateContainer />} />

              <Route path={ROUTES.FINANCE_TRANSACTION_LIST} element={<TransactionListContainer />} />

              <Route path={ROUTES.WAREHOUSE_ADJUSTMENT_LIST} element={<AdjustmentListContainer />} />
              <Route path={ROUTES.WAREHOUSE_ADJUSTMENT_CREATE} element={<AdjustmentCreateContainer />} />
              <Route path={ROUTES.WAREHOUSE_ADJUSTMENT_UPDATE} element={<AdjustmentUpdateContainer />} />

              <Route path={ROUTES.WAREHOUSE_GROUP_LIST} element={<WarehouseGroupListContainer />} />
              <Route path={ROUTES.WAREHOUSE_GROUP_CREATE} element={<WarehouseGroupCreateContainer />} />
              <Route path={ROUTES.WAREHOUSE_GROUP_UPDATE} element={<WarehouseGroupUpdateContainer />} />

              <Route path={ROUTES.FINANCE_PURCHASE_LIST} element={<PurchaseOrderListContainer />} />
              <Route path={ROUTES.FINANCE_PURCHASE_CREATE} element={<PurchaseCreateContainer />} />
              <Route path={ROUTES.FINANCE_PURCHASE_UPDATE} element={<PurchaseUpdateContainer />} />
              <Route path={ROUTES.FINANCE_AGENT_LIST} element={<AgentListContainer />} />
              <Route path={ROUTES.FINANCE_AGENT_CREATE} element={<AgentCreateContainer />} />
              <Route path={ROUTES.FINANCE_AGENT_EDIT} element={<AgentUpdateContainer />} />
              <Route
                path={ROUTES.CATALOG_AGENTS_ADDRESSES_MAP}
                element={<AgentsAddressesListContainer />}
              />
              <Route
                path={ROUTES.FINANCE_AGENT_CATEGORY_LIST}
                element={<ContactCategoryListContainer />}
              />
              <Route
                path={ROUTES.FINANCE_AGENT_CATEGORY_CREATE}
                element={<AgentCategoryCreateContainer />}
              />
              <Route
                path={ROUTES.FINANCE_AGENT_CATEGORY_EDIT}
                element={<AgentCategoryUpdateContainer />}
              />
              <Route
                path={ROUTES.FINANCE_SUPPLIER_PAYMENTS_LIST}
                element={<SupplierPaymentsListContainer />}
              />
              <Route
                path={ROUTES.FINANCE_SUPPLIER_PAYMENTS_CREATE}
                element={<SupplierPaymentsCreateContainer />}
              />
              <Route
                path={ROUTES.FINANCE_SUPPLIER_PAYMENTS_UPDATE}
                element={<SupplierPaymentsUpdateContainer />}
              />

              <Route
                path={ROUTES.FINANCE_CHART_OF_ACCOUNT_LISTING}
                element={<ChartOfAccountingListContainer />}
              />
              <Route
                path={ROUTES.FINANCE_CHART_OF_ACCOUNT_CREATE}
                element={<ChartOfAccountCreateContainer />}
              />

              <Route path={ROUTES.HR_EMPLOYEE_LIST} element={<EmployeeListContainer />} />
              <Route path={ROUTES.HR_EMPLOYEE_CREATE} element={<EmployeeCreateContainer />} />
              <Route path={ROUTES.HR_EMPLOYEE_UPDATE} element={<EmployeeUpdateContainer />} />
              <Route path={ROUTES.HR_TRACKING_LIST} element={<TrackingListContainer />} />

              <Route path={ROUTES.WAREHOUSE_MOVEMENT_LIST} element={<TransferListContainer />} />
              <Route path={ROUTES.WAREHOUSE_MOVEMENT_CREATE} element={<TransferCreateContainer />} />
              <Route path={ROUTES.WAREHOUSE_MOVEMENT_UPDATE} element={<TransferUpdateContainer />} />
              // Backend
              <Route path={ROUTES.BACKEND_COMPANY_LIST} element={<CompanyListContainer />} />
              <Route path={ROUTES.BACKEND_COMPANY_CREATE} element={<CompanyCreateContainer />} />
              <Route path={ROUTES.BACKEND_COMPANY_ADD_USER} element={<CompanyCreateUserContainer />} />
              <Route path={ROUTES.BACKEND_COMPANY_EDIT} element={<CompanyUpdateContainer />} />
              <Route path={ROUTES.BACKEND_NEWS_LIST} element={<NewsListContainer />} />
              <Route path={ROUTES.BACKEND_NEWS_CREATE} element={<NewsCreateContainer />} />
              <Route path={ROUTES.BACKEND_NEWS_UPDATE} element={<NewsUpdateContainer />} />
              <Route path={ROUTES.BACKEND_BILLING_LIST} element={<BillingPlanListContainer />} />
              <Route path={ROUTES.BACKEND_BILLING_CREATE} element={<BillingPlanCreateContainer />} />
              <Route path={ROUTES.BACKEND_BILLING_UPDATE} element={<BillingPlanUpdateContainer />} />
              <Route
                path={ROUTES.BACKEND_BILLING_CATEGORY_LIST}
                element={<BillingPLanCategoryListContainer />}
              />
              <Route
                path={ROUTES.BACKEND_BILLING_CATEGORY_CREATE}
                element={<BillingPlanCategoryCreateContainer />}
              />
              <Route
                path={ROUTES.BACKEND_BILLING_CATEGORY_UPDATE}
                element={<BillingPlanCategoryUpdateContainer />}
              />
              <Route
                path={ROUTES.BACKEND_SHARED_CONTACT_CATEGORY_LIST}
                element={<SharedContactCategoryListContainer />}
              />
              <Route
                path={ROUTES.BACKEND_SHARED_CONTACT_CATEGORY_CREATE}
                element={<SharedContactCategoryCreateContainer />}
              />
              <Route
                path={ROUTES.BACKEND_SHARED_CONTACT_CATEGORY_UPDATE}
                element={<SharedContactCategoryUpdateContainer />}
              />
              <Route
                path={ROUTES.BACKEND_SHARED_CONTACT_LIST}
                element={<SharedContactListContainer />}
              />
              <Route
                path={ROUTES.BACKEND_SHARED_CONTACT_CREATE}
                element={<SharedContactCreateContainer />}
              />
              <Route
                path={ROUTES.BACKEND_SHARED_CONTACT_UPDATE}
                element={<SharedContactUpdateContainer />}
              />
              // Marketing
              <Route path={ROUTES.MARKETING_RENT_LIST} element={<RentListContainer />} />
              <Route path={ROUTES.MARKETING_RENT_CREATE} element={<RentCreateContainer />} />
              <Route path={ROUTES.MARKETING_RENT_UPDATE} element={<RentUpdateContainer />} />
              <Route path={ROUTES.MARKETING_BONUS_LIST} element={<BonusListContainer />} />
              <Route path={ROUTES.MARKETING_BONUS_CREATE} element={<BonusCreateContainer />} />
              <Route path={ROUTES.MARKETING_BONUS_UPDATE} element={<BonusUpdateContainer />} />

              <Route path={ROUTES.REPORTS_WAREHOUSE_REMAINS} element={<StockListContainer />} />

              <Route path="*" element={<Navigate replace to={ROUTES.CATALOG_PRODUCT_LIST} />} />
            </>
          )}
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default AppRoutes
