import home from './home.json'
import product from './catalogs/products/product.json'
import productPackage from './catalogs/products/product-package.json'
import productCategory from './catalogs/products/product-category.json'
import mml from './catalogs/products/mml.json'
import brand from './catalogs/products/brand.json'
import country from './catalogs/products/country.json'
import currency from './catalogs/products/currency.json'
import priceLevel from './catalogs/products/price-level.json'
import discount from './catalogs/products/discount.json'
import contact from './catalogs/counteragents/contact.json'
import territory from './catalogs/counteragents/territory.json'
import contactCategory from './catalogs/counteragents/contact-category.json'
import warehouse from './warehouse/warehouse.json'
import warehouseGroup from './warehouse/warehouse-group.json'
import receive from './warehouse/receive.json'
import adjustment from './warehouse/adjustment.json'
import transfer from './warehouse/transfer.json'
import preOrder from './sales-order/pre-order.json'
import salesOrder from './sales-order/sales-order.json'
import common from './common.json'
import pick from './sales-order/pick.json'
import shipment from './sales-order/shipment.json'
import salesOrderReturn from './sales-order/sales-order-return.json'
import user from './hr/user.json'
import bonus from './marketing/bonus.json'
import rent from './marketing/rent.json'
import timeSlot from './settings/time-slot.json'
import customFields from './settings/custom-fields.json'
import approveProcess from './settings/approve-process.json'
import visits from './visits/visit.json'
import taskReturn from './visits/task-return.json'
import visitType from './visits/visit-type.json'
import visitComments from './visits/visit-comments.json'
import chartOfAccount from './finance/chart-of-account.json'
import supplierPayment from './finance/supplier-payment.json'
import payment from './finance/payment.json'
import transferCash from './finance/transfer-cash.json'
import manualEntry from './finance/manual-entry.json'
import bulkPayment from './finance/bulk-payment.json'
import transaction from './finance/transaction.json'
import consignment from './finance/consignment.json'
import news from './backend/news.json'
import company from './backend/company.json'
import billingCategory from './backend/billing-category.json'
import billingPlan from './backend/billing-plan.json'
import sharedContactCategory from './backend/shared-contact-category.json'
import sharedContact from './backend/shared-contact.json'
import appHeader from './main/app-header.json'

export const EN_TRANSLATION = {
  home,
  common,
  product,
  productPackage,
  productCategory,
  mml,
  brand,
  country,
  currency,
  priceLevel,
  discount,
  contact,
  territory,
  contactCategory,
  warehouse,
  warehouseGroup,
  receive,
  adjustment,
  transfer,
  preOrder,
  salesOrder,
  pick,
  shipment,
  salesOrderReturn,
  user,
  bonus,
  rent,
  timeSlot,
  customFields,
  approveProcess,
  visits,
  taskReturn,
  visitType,
  visitComments,
  chartOfAccount,
  supplierPayment,
  payment,
  transferCash,
  manualEntry,
  bulkPayment,
  transaction,
  consignment,
  news,
  company,
  billingCategory,
  billingPlan,
  sharedContactCategory,
  sharedContact,
  appHeader
}
